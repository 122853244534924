import { signal } from '@angular/core';

export type ExpandChangeEvent<DataType> = {
  data: DataType;
  isExpanded: boolean;
};

export type BaseTreeType<DataType> = {
  id: number | string;
  name: string;
  data: DataType;
  children?: Array<BaseTreeType<DataType>> | undefined;
};

export class TreeNode<DataType> {
  isExpanded = signal(false);

  id: number | string;

  name: string;

  expandable: boolean;

  collapsible: boolean;

  depth: number;

  data: DataType;

  parent?: TreeNode<DataType> | undefined;

  children?: TreeNode<DataType>[] | undefined;

  constructor(node: BaseTreeType<DataType>, parent: TreeNode<DataType> | undefined, depth = 0) {
    this.id = node.id;
    this.name = node.name;
    this.depth = depth;
    this.expandable = Boolean(node.children);
    this.data = node.data;
    this.parent = parent;
    this.collapsible = Boolean(parent) && this.expandable;
    this.children = this.expandable ? node.children?.map((n) => new TreeNode(n, this, this.depth + 1)) : undefined;
  }

  patch(callback: (data: DataType) => void, withChildren: boolean = false) {
    callback(this.data);

    if (withChildren) {
      this.children?.forEach((c) => c.patch(callback, withChildren));
    }
  }

  expand() {
    this.isExpanded.set(true);
  }

  collapse(withChildren: boolean = false) {
    this.isExpanded.set(false);

    if (withChildren) {
      this.children?.forEach((c) => c.collapse(withChildren));
    }
  }

  getIdList() {
    const list = [Number(this.id)];
    let node: TreeNode<DataType> = this;

    while (node.parent) {
      list.push(Number(node.parent.id));
      node = node.parent;
    }

    return list.reverse();
  }
}

export class Tree<DataType> extends Array<TreeNode<DataType>> {
  constructor(nodeList: BaseTreeType<DataType>[]) {
    super(...nodeList.map((e) => new TreeNode<DataType>(e, undefined)));
  }
}
