import { Injectable, Signal, WritableSignal, computed, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ItemId,
  StrategyFieldValue,
  StrategyItem,
  StrategyMatrix,
  StrategyMatrixValue,
  StrategyMatrixWithPresentValues,
} from '@type/strategy.type';
import { BaseTreeType, Tree, TreeNode } from '@type/tree/tree.type';
import { convertMatrixToData, createFieldName } from '../../utils/functions';

@Injectable()
export class StrategyTreeService {
  #getTree = () => this.tree!;

  private translate = inject(TranslateService);

  private tree: Signal<Tree<StrategyMatrixValue>> | undefined;

  private maxTreeDepth = 0;

  private translations = {
    yes: this.translate.instant('strategy.true'),
    no: this.translate.instant('strategy.false'),
    empty: this.translate.instant('strategy.empty'),
  };

  applyFirstLevelData = (
    initialMatrixConfig: Signal<StrategyMatrixWithPresentValues | undefined>,
    strategy: WritableSignal<StrategyItem | null>,
  ) => {
    this.maxTreeDepth = (strategy()?.strategy_fields.length || 1) - 1;

    this.tree = computed(
      () => new Tree<StrategyMatrixValue>(this.convertStrategyMatrixToTree(initialMatrixConfig(), strategy())),
    );

    return {
      getTree: this.#getTree,
    };
  };

  addChildren(options: {
    matrix: StrategyMatrix;
    presentValues: number[];
    branch: TreeNode<StrategyMatrixValue>;
    strategy: StrategyItem;
  }) {
    const {
      strategy: { strategy_fields: fields },
      presentValues,
      matrix,
      branch: { children, depth },
    } = options;

    const currentDepth = depth + 1;
    const currentFields = fields[currentDepth].strategy_field_values;

    if (children && currentFields && matrix) {
      const filteredFields = currentFields.filter((f) => presentValues.includes(f.id));
      const prepared = filteredFields.map((fv) => this.convertFieldValueToBaseTreeType(fv, matrix, currentDepth));

      children.push(...prepared.map((p) => new TreeNode(p, options.branch, currentDepth)));
    }
  }

  private convertStrategyMatrixToTree(
    opts: { matrix: StrategyMatrix | null | undefined; presentValues: number[] } | undefined,
    strategy: StrategyItem | null,
    level = 0,
  ): BaseTreeType<StrategyMatrixValue>[] {
    if (!(opts && strategy)) return [];
    const fields = strategy.strategy_fields;

    const createTree = (level: number) => {
      let tree: BaseTreeType<StrategyMatrixValue>[];
      tree ??= [];
      const currentFields = fields[level];

      if (currentFields) {
        let prepared = currentFields.strategy_field_values
          .filter(({ id }) => opts.presentValues.includes(id))
          .map((fv) => this.convertFieldValueToBaseTreeType(fv, opts.matrix!, 0));

        tree.push(...prepared);
      }
      return tree;
    };

    return createTree(level);
  }

  private convertFieldValueToBaseTreeType(fieldValue: StrategyFieldValue, matrix: StrategyMatrix, level: number) {
    return {
      id: fieldValue.id,
      name: createFieldName(fieldValue, this.translations),
      data: convertMatrixToData(matrix, fieldValue.id),
      children: this.initChildren(level),
    } as BaseTreeType<StrategyMatrixValue>;
  }

  private initChildren(currentDepth: number) {
    return currentDepth < this.maxTreeDepth ? [] : undefined;
  }
}
